import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const GatesTweet = makeShortcode("GatesTweet");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Reflections on Nexststrain`}</h1>
    <p>{`How does data get opened up and stay opened up? What roles do tools play? What roles do social behaviors play? What are the incentive gradients? `}</p>
    <p>{`One of the things I realized, coming as an outsider to science, is that there's in fact a lot of game theory. I didn't realize that by building a tool, we could change incentives. This was fascinating. `}</p>
    <p>{`The tool `}<em parentName="p">{`definitely`}</em>{` changed behavior. It was intended to. And it did. It created new value and that created new incentives and that changed behaviors. And the internet can do that again. What's it take? Well, some vision, and some generosity. `}</p>
    <h6>{`Creating better feedback loops with social scientific tools`}</h6>
    <h2>{`Nextstrain background & my involvement`}</h2>
    <p>{`From `}<a parentName="p" {...{
        "href": "nextstrain.org"
      }}>{`nextstrain.org`}</a>{`: "Nextstrain is an open-source project to harness the scientific and public health potential of pathogen genome data. We provide a continually-updated view of publicly available data alongside powerful analytic and visualization tools for use by the community. Our goal is to aid epidemiological understanding and improve outbreak response."`}</p>
    <GatesTweet mdxType="GatesTweet" />
    <p>{`My personal involvement with Nextstrain began with a weekend evening hack night project in 2014 to pull together real time statistics on the Ebola epidemic. This was very similar, in retrospect, to many of the hack projects that resulted in real time dashboards for COVID.`}</p>
    <blockquote className="twitter-tweet tw-align-center">
  <p lang="en" dir="ltr">
    <a href="https://t.co/wCgy8M0hLt">https://t.co/wCgy8M0hLt</a> is an
    auto-updating visualization of{" "}
    <a href="https://twitter.com/hashtag/Ebola?src=hash&amp;ref_src=twsrc%5Etfw">
      #Ebola
    </a>{" "}
    case counts and mortality by{" "}
    <a href="https://twitter.com/colinmegill?ref_src=twsrc%5Etfw">
      @colinmegill
    </a>{" "}
    et al. <a href="http://t.co/sukqUZGIaS">pic.twitter.com/sukqUZGIaS</a>
  </p>
  &mdash; Trevor Bedford (@trvrb) <a href="https://twitter.com/trvrb/status/521711843341901824?ref_src=twsrc%5Etfw">October 13, 2014</a>
    </blockquote>
    <p>{`Between 2015 and 2017, I worked with `}<a parentName="p" {...{
        "href": "https://bedford.io/"
      }}>{`Bedford Lab`}</a>{` to platformitize, generalize and expand `}<a parentName="p" {...{
        "href": "https://twitter.com/trvrb/status/569907432161353728"
      }}>{`the nextflu prototype`}</a>{` to handle arbitrary viral phylogeny datasets.`}</p>
    <blockquote className="twitter-tweet tw-align-center">
  <p lang="en" dir="ltr">
    Thanks to efforts from{" "}
    <a href="https://twitter.com/colinmegill?ref_src=twsrc%5Etfw">
      @colinmegill
    </a>
    , @jh_viz and <a href="https://twitter.com/richardneher?ref_src=twsrc%5Etfw">
      @richardneher
    </a>, we&#39;re relaunching <a href="https://t.co/WjdKmtoIuV">
      https://t.co/WjdKmtoIuV
    </a> today with tons of new features. <a href="https://t.co/0xKpjjH5s9">pic.twitter.com/0xKpjjH5s9</a>
  </p>
  &mdash; Trevor Bedford (@trvrb) <a href="https://twitter.com/trvrb/status/836608470463959042?ref_src=twsrc%5Etfw">February 28, 2017</a>
    </blockquote>
    <blockquote className="twitter-tweet tw-align-center">
  <p lang="en" dir="ltr">
    Thanks to{" "}
    <a href="https://twitter.com/colinmegill?ref_src=twsrc%5Etfw">
      @colinmegill
    </a>
    , <a href="https://t.co/WjdKmtGjmt">https://t.co/WjdKmtGjmt</a> now better links
    map and tree. We can show, for example, spread of Ebola across West Africa. <a href="https://t.co/ProTVjCwNH">pic.twitter.com/ProTVjCwNH</a>
  </p>
  &mdash; Trevor Bedford (@trvrb) <a href="https://twitter.com/trvrb/status/844289196940410881?ref_src=twsrc%5Etfw">March 21, 2017</a>
    </blockquote>
    <p>{`Nextstrain went on to win the Open Science Prize, and contribute to the surveillance of viruses like COVID. I was `}<a parentName="p" {...{
        "href": "https://twitter.com/colinmegill/status/1245249044647206912"
      }}>{`interviewed by Shirley Wu about the tool and how it's used to monitor virus evolution`}</a>{` in March 2020 at the outset of the coronavirus pandemic.`}</p>
    <blockquote className="twitter-tweet tw-align-center">
  <p lang="en" dir="ltr">
    <a href="https://t.co/5iPXiYxiZC">https://t.co/5iPXiYxiZC</a> wins the
    OpenSciencePrize!{" "}
    <a href="https://t.co/T4bbmlgRIE">https://t.co/T4bbmlgRIE</a> great collab
    with <a href="https://twitter.com/trvrb?ref_src=twsrc%5Etfw">@trvrb</a>{" "}
    <a href="https://twitter.com/colinmegill?ref_src=twsrc%5Etfw">
      @colinmegill
    </a>{" "}
    @jh_viz
  </p>
  &mdash; Richard Neher (@richardneher) <a href="https://twitter.com/richardneher/status/836607453945081857?ref_src=twsrc%5Etfw">February 28, 2017</a>
    </blockquote>
    <blockquote className="twitter-tweet tw-align-center">
  <p lang="en" dir="ltr">
    Visualize the virus radiating out from China, mutating as it spreads.
    <br />
    <br />
    Right: map with different strains color-coded
    <br />
    Left: phylogenetic tree, showing how individual strains mutate and diverge
    <br />
    <br />
    Very impressive work by{" "}
    <a href="https://twitter.com/trvrb?ref_src=twsrc%5Etfw">@trvrb</a> and the
    group at Nextstrain.{" "}
    <a href="https://t.co/p3wkhvtDPL">https://t.co/p3wkhvtDPL</a>{" "}
    <a href="https://t.co/BuoxBaW81A">pic.twitter.com/BuoxBaW81A</a>
  </p>
  &mdash; balajis.com (@balajis) <a href="https://twitter.com/balajis/status/1233190944129929217?ref_src=twsrc%5Etfw">February 28, 2020</a>
    </blockquote>
    <p>{`The Nextstrain paper was published in `}<a parentName="p" {...{
        "href": "https://doi.org/10.1093/bioinformatics/bty407"
      }}>{`Bioinformatics, Volume 34, Issue 23, 01 December 2018, Pages 4121–4123`}</a>{`.`}</p>
    <p>{`The AGPLv3 codebase `}<a parentName="p" {...{
        "href": "https://github.com/nextstrain/auspice"
      }}>{`is available on GitHub`}</a>{`.`}</p>
    <p>{`Nextstrain has been featured in `}<a parentName="p" {...{
        "href": "https://www.nytimes.com/2020/04/22/us/coronavirus-sequencing.html"
      }}>{`NYT`}</a>{`, `}<a parentName="p" {...{
        "href": "https://www.technologyreview.com/2020/03/04/905512/gene-sleuths-are-tracking-the-coronavirus-outbreak-as-it-happens/"
      }}>{`MIT Tech Review`}</a>{`, `}<a parentName="p" {...{
        "href": "https://www.scientificamerican.com/article/virus-mutations-reveal-how-covid-19-really-spread1/"
      }}>{`Scientific American`}</a>{`, `}<a parentName="p" {...{
        "href": "https://www.vanityfair.com/news/2020/03/mapping-the-spread-of-a-deadly-disease"
      }}>{`Vanity Fair`}</a>{`, `}<a parentName="p" {...{
        "href": "https://www.bloomberg.com/news/articles/2020-03-24/how-did-covid-19-spread-viral-genetics-leave-trail-of-clues"
      }}>{`Bloomberg`}</a>{` and won a `}<a parentName="p" {...{
        "href": "https://winners.webbyawards.com/2020/specialachievement/270/nextstrainorg"
      }}>{`Webby`}</a>{`.`}</p>
    <p>{`What are the incentives? `}</p>
    <p>{`Public wealth`}</p>
    <p>{`What we could be doing`}</p>
    <p>{`What labs are incentivized to do, how next strain was different and why it was great `}</p>
    <p>{`What the social gradients are and the game theory `}</p>
    <p>{`technology alone, independent of funding, can be enough to change the game theory between the labs from "compete" to "cooperate", and nextstrain demonstrated this: by increasing the utility of shared data, there were incentives to share, which neutralized competitive advantages for withholding data, and this was overall cheaper than requiring this to be done via funding, and because the tool was open source and stewarded by an institution, there were no counter incentives in the way of global cooperation`}</p>
    <p>{`Create a gravity well `}</p>
    <p>{`Sidney feedback:`}</p>
    <pre><code parentName="pre" {...{}}>{`- really compelling thesis
- less about "neutralizing" the advantage for competing, competition still happens
- relative cost benefit ratio that people are calculating, the benefit of seeing this on nextstrain is what exactly
- cost of withholding is what?
- cost of sharing is what? hording and publishing independently
- the benefit of sharing publicly that was demonstrated publicly
    - nextstrain provides benefit to other people, helps you see what your samples are related to, mostly community benefit
    - weird vortex where instead of tragedy of commons success of commons
    - having samples up there gets you more recognition in some cases, and you can use other people's data to tell a better story overall
    - when your paper or the thing you're observing is big enough for a splashy thing...
    - made it obvious when sequences weren't being shared and 
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      